import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MaterialDrawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import config from 'config';
import { closeDrawer } from 'redux/modules/app';

const styles = theme => ({
  drawer: {
    width: config.layout.drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: config.layout.drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerButton: {
    color: 'white'
  }
});

@connect(
  state => ({
    drawerIsOpen: state.app.drawerOpen,
    bookColor: state.bookReducer && state.bookReducer.book.metadata && state.bookReducer.book.metadata.color
  }),
  { mappedCloseDrawer: closeDrawer }
)
class Drawer extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    drawerIsOpen: PropTypes.bool.isRequired,
    bookColor: PropTypes.string,
    mappedCloseDrawer: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  };

  static defaultProps = {
    bookColor: '',
    children: undefined
  };

  handleDrawerClose = () => {
    const { mappedCloseDrawer } = this.props;
    mappedCloseDrawer();
  };

  render() {
    const {
      classes, drawerIsOpen, bookColor, children
    } = this.props;
    const appBarStyle = bookColor ? { backgroundColor: bookColor } : null;
    return (
      <MaterialDrawer
        className={classes.drawer}
        open={drawerIsOpen}
        onClose={this.handleDrawerClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader} style={appBarStyle}>
          <IconButton classes={{ root: classes.drawerButton }} onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div>{children}</div>
      </MaterialDrawer>
    );
  }
}

export default withStyles(styles)(Drawer);
