import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { provideHooks } from 'redial';
import PropTypes from 'prop-types';
import reducer, * as chapterActions from 'redux/modules/chapter';
import bookReducer, * as bookActions from 'redux/modules/book';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LessonAccordion from '../../components/LessonAccordion/LessonAccordion';

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(40),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.secondary
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary
  }
});

const mapStateToProps = (state, routerProps) => ({
  categoryShortName: routerProps.match.params.categoryShortName,
  bookName: routerProps.match.params.bookName,
  chapterName: routerProps.match.params.chapterName,
  lessonName: routerProps.match.params.lessonName,
  bookColor: state.bookReducer.book.metadata.color,
  lessons: state.chapter.lessons
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...chapterActions,
    ...bookActions
  };
}

@provideHooks({
  fetch: async ({ store: { dispatch, getState, inject }, params: { bookName, categoryShortName, chapterName } }) => {
    inject({ chapter: reducer, bookReducer });

    const state = getState();

    if (state.online) {
      await Promise.all([
        dispatch(chapterActions.getChapterLessons(categoryShortName, bookName, chapterName)),
        dispatch(bookActions.getBook(categoryShortName, bookName))
      ]);
    }
  }
})
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Lesson extends Component {
  static propTypes = {
    categoryShortName: PropTypes.string,
    bookName: PropTypes.string,
    chapterName: PropTypes.string,
    lessonName: PropTypes.string,
    lessons: PropTypes.arrayOf(PropTypes.object),
    bookColor: PropTypes.string
  };

  static defaultProps = {
    categoryShortName: '',
    bookName: '',
    chapterName: '',
    lessonName: '',
    lessons: [],
    bookColor: ''
  };

  render() {
    const {
      categoryShortName, bookName, chapterName, lessonName, lessons, bookColor
    } = this.props;

    return (
      <div className="container">
        <Helmet title={`${bookName.toUpperCase()} Lesson ${lessonName}`} />
        <div style={{ height: `${20}px` }} />
        <LessonAccordion
          selectedLesson={lessonName}
          bookColor={bookColor}
          lessons={lessons}
          chapterName={chapterName}
          bookName={bookName}
          categoryShortName={categoryShortName}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Lesson);
