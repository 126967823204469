import apiClient from '../../helpers/apiClient';

const LOAD = 'hw-help/lesson/LOAD';
const LOAD_SUCCESS = 'hw-help/lesson/LOAD_SUCCESS';
const LOAD_FAIL = 'hw-help/lesson/LOAD_FAIL';
const c3poClient = apiClient();

const initialState = {
  loaded: false,
  content: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        content: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getProblemContent(catShortName, bookName, chapterName, lessonName, problemName) {
  const apiPromise = new Promise((resolve, reject) => {
    if (problemName) {
      c3poClient
        .getHomeworkHelpProblemHTML(catShortName, bookName, chapterName, lessonName, problemName)
        .exec((err, result, response) => {
          if (response && response.text) {
            resolve(response.text);
          } else if (err) {
            console.log('Problem Reducer Error ', err);
            console.log('Problem Reducer result ', result);
            console.log('Problem Reducer Response ', response);
            reject(err.error);
          } else {
            console.log('Unknown problem fetching a problem... oooooohhhh the irony.');
            reject(new Error('Waiting for valid problem name'));
          }
        });
    } else {
      console.log('problemName is NULL. Refusing to Fetch Problem');
      reject(new Error('Waiting for valid problem name'));
    }
  });

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async () => apiPromise
  };
}
