import apiClient from '../../helpers/apiClient';

const LOAD = 'hw-help/chapter/LOAD';
const LOAD_SUCCESS = 'hw-help/chapter/LOAD_SUCCESS';
const LOAD_FAIL = 'hw-help/chapter/LOAD_FAIL';
const c3poClient = apiClient();

const initialState = {
  loaded: false,
  lessons: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        lessons: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getChapterLessons(catShortName, bookName, chapterName) {
  const apiPromise = new Promise((resolve, reject) => {
    c3poClient.getHomeworkHelpChapterLessons(catShortName, bookName, chapterName).exec((err, response) => {
      if (err) {
        console.log('Chapter Reducer Error ', err);
        reject(err.message);
      } else {
        resolve(response);
      }
    });
  });

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async () => apiPromise
  };
}
