import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  bookCoverImage: {
    margin: '20px',
    width: '200px'
  }
};

const BookCover = ({ classes, book, categoryShortName }) => {
  const link = `./category/${categoryShortName}/textbook/${book.name}`;
  const image = book.coverMedia && book.coverMedia.publicUrl ? book.coverMedia.publicUrl : 'default-book-cover.png';

  return (
    <div>
      <a href={link}>
        <img className={classes.bookCoverImage} alt={`${book.displayName}`} src={image} />
      </a>
    </div>
  );
};
BookCover.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  book: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    coverMedia: PropTypes.shape({
      publicUrl: PropTypes.string
    })
  }).isRequired,
  categoryShortName: PropTypes.string.isRequired
};

export default withStyles(styles)(BookCover);
