import apiClient from '../../helpers/apiClient';

const LOAD = 'hw-help/categories/LOAD';
const LOAD_SUCCESS = 'hw-help/categories/LOAD_SUCCESS';
const LOAD_FAIL = 'hw-help/categories/LOAD_FAIL';
const c3poClient = apiClient();

const initialState = {
  loaded: false,
  categories: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        categories: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function listCategories() {
  const thing = new Promise((resolve, reject) => {
    c3poClient.getHomeworkHelpCategories().exec((err, response) => {
      if (err) {
        console.log('Category Reducer Error ', err);
        reject(err.message);
      } else {
        resolve(response);
      }
    });
  });

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async () => thing
  };

  // return {
  //   types: [LIST_VISITORS, LIST_VISITORS_SUCCESS, LIST_VISITORS_FAIL],
  //   promise: async ({ client }) => client.get('/visitors')
  // };
}
