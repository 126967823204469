import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApp } from 'hoc';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '80.00%',
    color: 'white',
    flexShrink: 0
  },
  expandIcon: {
    color: 'white'
  },
  details: {
    flexWrap: 'wrap'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.secondary
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary
  }
});

@withApp
class LessonAccordion extends Component {
  static propTypes = {
    lessons: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.objectOf(PropTypes.string),
    bookName: PropTypes.string.isRequired,
    categoryShortName: PropTypes.string.isRequired,
    chapterName: PropTypes.string.isRequired,
    bookColor: PropTypes.string.isRequired,
    selectedLesson: PropTypes.string
  };

  static defaultProps = {
    classes: {},
    selectedLesson: null
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: props.selectedLesson
    };
  }

  render() {
    const {
      classes, categoryShortName, bookName, chapterName, lessons, bookColor
    } = this.props;

    if (!lessons) return null;

    const { expanded } = this.state;

    const summaryStyle = { backgroundColor: bookColor };

    const renderHandleChange = panel => (event, spanded) => {
      this.setState({
        expanded: spanded ? panel : false
      });
    };

    const headerProblem = { paddingRight: '20px' };
    const chapterLessons = lessons.map(lesson => (
      <ExpansionPanel key={lesson.name} expanded={expanded === lesson.name} onChange={renderHandleChange(lesson.name)}>
        <ExpansionPanelSummary
          style={summaryStyle}
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography className={classes.heading} variant="h3">
            Lesson {lesson.name}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {lesson.lessonContentContainers.map(lessonContentContainer => {
            const link = `/category/${categoryShortName}/textbook/${bookName}/chapter/${chapterName}/lesson/${
              lesson.name
            }/problem/${lessonContentContainer.name}`;
            return (
              <div key={lessonContentContainer.name}>
                <a href={link}>
                  <h4 style={headerProblem}>{lessonContentContainer.name}</h4>
                </a>
              </div>
            );
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));

    return chapterLessons;
  }
}

export default withStyles(styles)(LessonAccordion);
