import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { provideHooks } from 'redial';
import PropTypes from 'prop-types';
import reducer, * as chapterActions from 'redux/modules/chapter';
import bookReducer, * as bookActions from 'redux/modules/book';
import { connect } from 'react-redux';
import LessonAccordion from '../../components/LessonAccordion/LessonAccordion';

@provideHooks({
  fetch: async ({ store: { dispatch, getState, inject }, params: { bookName, categoryShortName, chapterName } }) => {
    inject({ chapter: reducer, bookReducer });

    const state = getState();

    if (state.online) {
      await Promise.all([
        dispatch(chapterActions.getChapterLessons(categoryShortName, bookName, chapterName)),
        dispatch(bookActions.getBook(categoryShortName, bookName))
      ]);
    }
  }
})
@connect(
  (state, routerProps) => ({
    categoryShortName: routerProps.match.params.categoryShortName,
    bookName: routerProps.match.params.bookName,
    chapterName: routerProps.match.params.chapterName,
    lessons: state.chapter.lessons,
    bookColor: state.bookReducer.book.metadata.color
  }),
  { ...chapterActions, ...bookActions }
)
class Chapter extends Component {
  static propTypes = {
    categoryShortName: PropTypes.string,
    bookName: PropTypes.string,
    chapterName: PropTypes.string,
    lessons: PropTypes.arrayOf(PropTypes.object),
    bookColor: PropTypes.string
  };

  static defaultProps = {
    categoryShortName: '',
    bookName: '',
    chapterName: '',
    lessons: [],
    bookColor: ''
  };

  render() {
    const {
      bookName, chapterName, lessons, categoryShortName, bookColor
    } = this.props;

    return (
      <div className="container">
        <Helmet title={`${bookName.toUpperCase()} Chapter ${chapterName}`} />
        <div style={{ height: `${20}px` }} />
        <LessonAccordion
          bookColor={bookColor}
          lessons={lessons}
          chapterName={chapterName}
          bookName={bookName}
          categoryShortName={categoryShortName}
        />
      </div>
    );
  }
}
export default Chapter;
