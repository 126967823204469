import React, { Component } from 'react';
// import Helmet from 'react-helmet';
import { provideHooks } from 'redial';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bookReducer, * as bookActions from 'redux/modules/book';
import Helmet from 'react-helmet';

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '33.33%',
    color: 'white',
    flexShrink: 0
  },
  expandIcon: {
    color: 'white'
  },
  details: {
    flexWrap: 'wrap'
  },
  lesson: {
    paddingRight: theme.spacing.unit * 2
  }
});

@provideHooks({
  fetch: async ({ store: { dispatch, getState, inject }, params: { bookName, categoryShortName } }) => {
    inject({ bookReducer });

    const state = getState();
    console.log('Book.provideHooks().state', state);

    if (state.online) {
      await Promise.all([
        dispatch(bookActions.getBookChapters(categoryShortName, bookName)),
        dispatch(bookActions.getBook(categoryShortName, bookName))
      ]).then(r => {
        console.log('Book.provideHooks().results', r);
      });
    }
  }
})
@connect(
  (state, routerProps) => ({
    categoryShortName: routerProps.match.params.categoryShortName,
    bookName: state.bookReducer.book.name,
    chapters: state.bookReducer.chapters,
    bookColor: state.bookReducer.book.metadata.color
  }),
  { ...bookActions }
)
class Book extends Component {
  state = {
    expanded: null
  };

  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    categoryShortName: PropTypes.string,
    bookName: PropTypes.string,
    chapters: PropTypes.arrayOf(PropTypes.object),
    bookColor: PropTypes.string
  };

  static defaultProps = {
    categoryShortName: '',
    bookName: '',
    chapters: [],
    bookColor: ''
  };

  render() {
    const {
      classes, categoryShortName, bookName, chapters, bookColor
    } = this.props;
    console.log('Book.render().chapters', chapters);

    const { expanded } = this.state;

    const summaryStyle = { backgroundColor: bookColor };

    const renderHandleChange = panel => (event, spanded) => {
      console.log(`renderHandleChange called with panel : ${panel} event : ${event} expanded : ${spanded}`);
      this.setState({
        expanded: spanded ? panel : false
      });
    };

    return (
      <div className="container">
        <Helmet title={`${bookName.toUpperCase()}`} />
        <div style={{ height: `${20}px` }} />
        <div>
          {chapters.map(chapter => (
            <ExpansionPanel
              key={chapter.name}
              expanded={expanded === chapter.name}
              onChange={renderHandleChange(chapter.name)}
            >
              <ExpansionPanelSummary
                style={summaryStyle}
                classes={{ expandIcon: classes.expandIcon }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className={classes.heading} variant="h3">
                  Chapter {chapter.name}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                {chapter.lessons.map(lesson => {
                  const link = `/category/${categoryShortName}/textbook/${bookName}/chapter/${chapter.name}/lesson/${
                    lesson.name
                  }`;
                  return (
                    <div key={lesson.name} className={classes.lesson}>
                      <a href={link}>
                        <h4>{lesson.name}</h4>
                      </a>
                    </div>
                  );
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Book);
