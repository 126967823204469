// import React from 'react';
import { connect } from 'react-redux';

const Restrict = ({ roles, userRole, children }) => {
  if (roles.includes(userRole)) return children;
  return false;
};

const mapStateToProps = state => ({
  roles: state.auth.roles
});

export default connect(mapStateToProps)(Restrict);
