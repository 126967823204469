import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { provideHooks } from 'redial';
import PropTypes from 'prop-types';
import reducer, * as categoryActions from 'redux/modules/categories';
import { connect } from 'react-redux';
import { withApp } from 'hoc';
import { withStyles } from '@material-ui/core/styles';

import BookCover from '../../components/BookCover/BookCover';
import Restrict from '../../components/Restrict';

const styles = {
  root: {
    width: '100%'
  },
  booksGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
};

/* eslint-disable max-len */
@provideHooks({
  fetch: async ({ store: { dispatch, getState, inject } }) => {
    inject({ categories: reducer });

    const state = getState();

    if (state.online) {
      return dispatch(categoryActions.listCategories());
    }
  }
})
@connect(
  state => ({
    categories: state.categories.categories,
    categoryShortName: state.categoryShortName
  }),
  { ...categoryActions }
)
@withApp
class HWCategory extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    app: PropTypes.shape({
      service: PropTypes.func
    }),
    categories: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    categories: [],
    app: {}
  };

  render() {
    const { classes, categories } = this.props;

    return (
      <div className="container">
        <Helmet title="Homework Help Categories" />
        <div>
          {categories.map(c => {
            const categoryDisplay = (
              <div key={c.shortName}>
                <h1>{c.displayName}</h1>
                <br />
                <div className={classes.booksGrid}>
                  {c.books.map(b => (
                    <BookCover key={b.name} book={b} categoryShortName={c.shortName} />
                  ))}
                </div>
              </div>
            );
            if (c.shortName === 'DEV') {
              return (
                <Restrict key={c.shortName} userRole="CPM_EBOOK_EDITOR">
                  {categoryDisplay}
                </Restrict>
              );
            }
            return categoryDisplay;
          })}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(HWCategory);
