import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import { provideHooks } from 'redial';
import PropTypes from 'prop-types';
import redProblem, * as problemActions from 'redux/modules/problem';
import chapterReducer, * as chapterActions from 'redux/modules/chapter';
import bookReducer, * as bookActions from 'redux/modules/book';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from 'components/Drawer/Drawer';
import LessonAccordion from '../../components/LessonAccordion/LessonAccordion';

const styles = theme => ({
  root: {
    position: 'relative'
  },

  content: {
    padding: `${theme.spacing.unit * 2}px 0`,
    '@media (min-width: 768px)': {
      paddingLeft: `${70}px`
    }
  },
  problemNumber: {
    marginTop: `${theme.spacing.unit * 2}px`,
    whiteSpace: 'nowrap',
    fontSize: 'larger',
    fontWeight: 'bold',
    '@media (min-width: 768px)': {
      position: 'absolute',
      left: '0',
      top: `${theme.spacing.unit * 2}px`,
      marginTop: 0
    }
  }
});

/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-danger */

@provideHooks({
  fetch: async ({
    store: { dispatch, getState, inject },
    params: {
      bookName, categoryShortName, chapterName, lessonName, problemName
    }
  }) => {
    inject({ problem: redProblem, chapter: chapterReducer, bookReducer });

    const state = getState();

    if (state.online) {
      await Promise.all([
        dispatch(problemActions.getProblemContent(categoryShortName, bookName, chapterName, lessonName, problemName)),
        dispatch(bookActions.getBook(categoryShortName, bookName)),
        dispatch(chapterActions.getChapterLessons(categoryShortName, bookName, chapterName))
      ]);
    }
  }
})
@connect((state, routerProps) => ({
  content: state.problem.content,
  lessons: state.chapter.lessons,
  bookColor: state.bookReducer && state.bookReducer.book.metadata && state.bookReducer.book.metadata.color,
  categoryShortName: routerProps.match.params.categoryShortName,
  bookName: routerProps.match.params.bookName,
  chapterName: routerProps.match.params.chapterName,
  lessonName: routerProps.match.params.lessonName,
  problemName: routerProps.match.params.problemName
}))
class Problem extends Component {
  static propTypes = {
    content: PropTypes.string,
    lessons: PropTypes.arrayOf(PropTypes.object),
    bookColor: PropTypes.string,
    categoryShortName: PropTypes.string,
    bookName: PropTypes.string,
    chapterName: PropTypes.string,
    lessonName: PropTypes.string,
    problemName: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired
  };

  static defaultProps = {
    content: '',
    lessons: [],
    bookColor: '',
    categoryShortName: '',
    bookName: '',
    chapterName: '',
    lessonName: '',
    problemName: ''
  };

  componentDidMount() {
    onC3POProblemWindowLoad();
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, ReactDOM.findDOMNode(this)]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      console.log('Problem componentDidUpdate');
      onC3POProblemWindowLoad();
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, ReactDOM.findDOMNode(this)]);
    }
  }

  render() {
    const {
      classes,
      content,
      lessons,
      bookColor,
      categoryShortName,
      bookName,
      chapterName,
      lessonName,
      problemName
    } = this.props;
    const problemContent = 'problemContent';
    const problemID = `${problemName}-problemContent`;

    return (
      <div className="container">
        <Drawer>
          <LessonAccordion
            selectedLesson={lessonName}
            bookColor={bookColor}
            lessons={lessons}
            chapterName={chapterName}
            bookName={bookName}
            categoryShortName={categoryShortName}
          />
        </Drawer>

        <Helmet title={`${bookName.toUpperCase()} Problem ${problemName}`} />
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.problemNumber}>{problemName}.</div>
          <main className={classes.content}>
            <div className={classes.drawerHeader}>
              <div id={problemID} className={problemContent} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </main>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Problem);
